import React from "react";

export const MobileRoadmap = React.forwardRef((props, ref) => {
  return (
    <div
      id="roadmap"
      ref={ref}
      className="relative pt-32 md:pt-40 lg:pt-[100px] px-5 md:px-10 "
    >
      <div className=" ">
        <div className=" text-center">
          <h1 className=" text-secondary font-semibold text-3xl leading-10">
            Bagaimana Comute Bekerja?
          </h1>
          <p className=" mt-2 text-comuteDark text-base leading-6 ">
            Yuk, telusuri fitur-fitur yang ada di Comute!
          </p>
        </div>
        <div
          className=" mt-[50px] mb-20
           p-6 relative flex flex-row justify-between bg-neutral "
        >
          <div className=" absolute left-0 sm:left-5 top-0 ">
            <img src="/images/number/sm-phone-1.png" alt="" />
          </div>
          <div className=" ml-auto w-[175px] lg:w-[370px]">
            <img src="/images/number/01.png" alt=""  />
            <h1 className=" text-black text-lg ">
              Tentukan{" "}
              <span className=" font-semibold text-primary">Stasiun</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Isi stasiun asal & tujuan, lalu tekan ‘Mulai Perjalanan’
            </p>
          </div>
        </div>
        <div
          className=" mt-[50px] mb-20
           p-3 relative  flex flex-row justify-between bg-neutral "
        >
          <div className=" w-[175px] lg:w-[370px]">
            <img src="/images/number/02.png" alt="" />
            <h1 className=" text-black text-lg ">
              Lihat{" "}
              <span className=" font-semibold text-primary">
                Detail Perjalanan
              </span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Kamu bisa lihat sisa stasiun, rute, dan stasiun yang akan kamu
              lewati berikutnya!
            </p>
          </div>
          <div className="absolute -right-10 top-[-50px] ">
            <img src="/images/number/sm-phone-2.png" alt="" />
          </div>
        </div>
        <div
          className=" mt-[50px] mb-20
           p-3 flex flex-row justify-between bg-neutral"
        >
          <div className="relative ">
            <img src="/images/number/phone-3.png" alt="" />
          </div>
          <div className=" w-[370px]">
            <img src="/images/number/03.png" alt="" />
            <h1>
              Dapatkan{" "}
              <span className=" font-semibold text-primary">Notifikasi</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Comute akan memberi tahu jika kamu harus turun di stasiun transit
              atau tiba di stasiun tujuan.
            </p>
          </div>
        </div>
        <div
          className=" relative mt-[50px] mb-20
           p-3 flex flex-row justify-between bg-neutral"
        >

          <div className=" w-[370px]">
            <img src="/images/number/04.png" alt="" />
            <h1>
              Simpan{" "}
              <span className=" font-semibold text-primary">Rute Favorit</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Setelah selesai perjalanan, kamu bisa menyimpannya menjadi rute
              favorit kamu.
            </p>
          </div>
          <div className="  ">
            <img src="/images/number/phone-4.png" alt="" />
          </div>
        </div>
        <div
          className=" relative mt-[50px] mb-20
            p-3 flex flex-row justify-between bg-neutral"
        >
          <div className="absolute -left-1 top-[-50px] ">
            <img src="/images/number/sm-phone-5.png" alt="" />
          </div>

          <div className=" relative">
          </div>
          <div className=" w-[185px] lg:w-[470px]">
            <img src="/images/number/05.png" alt="" />
            <h1 className=" mb-2">
              Bantuan{" "}
              <span className=" font-semibold text-primary">Komunikasi</span>
            </h1>
            <p className=" text-sm text-black">
              Cukup memilih atau mengetik pertanyaan, lalu dapatkan jawaban
              lawan bicara dengan fitur live transcribe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
})
