import React from "react";

export const Newsletter = React.forwardRef((props, ref) => {
  return (
    <div
      id="newsletter"
      ref={ref}
      className=" mt-[50px] flex flex-col md:flex-row justify-between px-5 md:px-10 xl:px-[100px] items-center py-5 md:py-10 py-[60px]"
      style={{
        backgroundImage: `url("/images/newsletter-background.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" text-center md:text-left ">
        <h1 className=" font-semibold text-xl lg:text-3xl text-secondary">
          Dapatkan Kabar Terbaru dari Comute
        </h1>
        <p className=" mt-2 text-black text-sm md:text-base leading-6 font-light ">
          Daftarkan email kamu untuk mendapatkan kabar terkini seperti versi
          terbaru dari aplikasi Comute!
        </p>
        <form
          className=" mt-10 max-w-[640px] p-0"
          action="https://tinyletter.com/comuteapp"
          method="post"
          target="popupwindow"
          onSubmit="window.open('https://tinyletter.com/comuteapp', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
          >
          <div class="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
            <div class="relative w-full">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-primary"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
              </div>
              <input
                class="block p-3 pl-10 w-full text-sm font-400 text-gray-900 bg-neutral max-w-[530px] h-[55px] rounded-lg sm:rounded-none sm:rounded-l-lg focus:ring-primary-500"
                placeholder="Masukkan email kamu..."
                type="email"
                id="tlemail"
                name="email"
                required
              />
              <input type="hidden" value="1" name="embed" />
            </div>
            <div className=" ml-2">
              <button
                type="submit"
                name="subscribe"
                class=" h-[50px] w-[167px] md:h-[55px] py-3 px-5 md:w-[117px] text-sm font-medium text-center text-white bg-primary rounded-lg cursor-pointer hover:bg-secondary focus:ring-4 focus:ring-primary-300"
              >
                Kirim</button>
            </div>
          </div>
        </form>
      </div>
      <img
        src="/images/newsletter-avatar.png"
        className=" w-[85px] h-[25px] lg:w-auto lg:h-auto m-0 hidden lg:block"
        alt=""
      />
    </div>
  );
})
