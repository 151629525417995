import {Hero} from "../component/Hero";
import Navbar from "../component/Navbar";
import {Roadmap} from "../component/Roadmap";
import {MobileRoadmap} from "../component/Roadmap/Mobile";
import Video from "../component/Video";
import Banner from "../component/Banner";
import {Team} from "../component/Team";
import {Newsletter} from "../component/Newsletter";
import Footer from "../component/Footer";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useEffect, useRef, useState } from "react";

function Home() {

  const { width } = useWindowDimensions()

  const heroRef = useRef();
  const roadmapRef = useRef();
  const teamRef = useRef();
  const newsletterRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);

  const links = [
    { to: "hero", text: "Tentang", headerRef: heroRef  },
    { to: "roadmap", text: "Fitur", headerRef: roadmapRef },
    { to: "team", text: "Developer", headerRef: teamRef },
    { to: "newsletter", text: "Buletin", headerRef: newsletterRef },
  ];

  useEffect(() => {
    const handleScroll = (e) => {
      if ((heroRef.current.offsetTop - window.scrollY < window.innerHeight) && roadmapRef.current.offsetTop - window.scrollY >= window.innerHeight) {
        setActiveIndex(0)
      }
      else if (roadmapRef.current.offsetTop - window.scrollY < window.innerHeight && teamRef.current.offsetTop - window.scrollY >= window.innerHeight) {
        setActiveIndex(1)
      }
      else if (teamRef.current.offsetTop - window.scrollY < window.innerHeight && newsletterRef.current.offsetTop - window.scrollY + 200 >= window.innerHeight) {
        setActiveIndex(2)
      }
      else if ((newsletterRef.current.offsetTop - window.scrollY) < window.innerHeight && teamRef.current.offsetTop > window.innerHeight) {
        setActiveIndex(3)
      }
    }
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <div className=" font-sans">
      <Navbar links={links} activeIndex={activeIndex} />
      <Hero ref={heroRef} />
      <Video />
      {
        width > 425 && (<Roadmap ref={roadmapRef} />)
      }
      {
        width <= 425 && (<MobileRoadmap ref={roadmapRef} />)
      }
      <Banner />
      <Team ref={teamRef} />
      <Newsletter ref={newsletterRef} />
      <Footer />
    </div>
  );
}

export default Home;
