import React from "react";

export default function PrivacyAndPolicy() {
  return (
    <div className=" p-5 md:py-5 mx-auto w-full max-w-[880px] font-sans">
      <h2 className=" my-2 mb-10">Terakhir diperbarui: 27 November 2022</h2>
      <p className=" my-2">
        Kebijakan Privasi berikut ini menjelaskan bagaimana Comute mengumpulkan,
        menyimpan, menggunakan, mengolah, mengungkapkan, dan melindungi Data
        Pribadi Anda berupa informasi lokasi GPS waktu-nyata (real-time).
        Kebijakan Privasi ini berlaku untuk semua pengguna aplikasi kami. Harap
        baca Kebijakan Privasi ini secara menyeluruh untuk memastikan bahwa Anda
        memahami praktik perlindungan data kami.
      </p>

      <div className=" mt-8">
        <h1 className=" font-bold my-2">PENGAKUAN DAN PERSETUJUAN</h1>
        <p className=" my-2">
          Dengan menyetujui Kebijakan Privasi, Anda menyatakan bahwa Anda telah
          membaca, memahami dan menyetujui segala ketentuan Kebijakan Privasi
          ini.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2">PENGUMPULAN DATA PRIBADI</h1>
        <p className=" my-2">
          Kami menggunakan Data Pribadi saat Anda menggunakan aplikasi kami,
          berupa informasi lokasi GPS waktu-nyata (real-time). Jika Anda memilih
          untuk tidak menyediakan informasi tersebut, kami tidak akan dapat
          menyediakan aplikasi kepada Anda secara normal.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2"> PENGGUNAAN DATA PRIBADI</h1>
        <p className=" my-2">
          Comute dapat menggunakan Data Pribadi Anda berupa informasi lokasi GPS
          waktu-nyata (real-time) untuk memberi Anda berbagai layanan dan fungsi
          yang tersedia di aplikasi kami.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2">KEAMANAN DATA</h1>
        <p className=" my-2">
          Comute dapat menggunakan Data Pribadi Anda berupa informasi lokasi GPS
          waktu-nyata (real-time) untuk memberikan Anda layanan dan fungsi yang
          tersedia di aplikasi kami. Kami menggunakan Data Pribadi Anda selama
          waktu tertentu untuk memenuhi tujuan dari aplikasi kami. Setelah Data
          Pribadi Anda tidak lagi diperlukan untuk layanan, kami dapat mengambil
          langkah-langkah untuk menghapus, menghancurkan, atau mencegah akses
          atau penggunaan Data Pribadi tersebut untuk tujuan apapun. Kami akan
          memberlakukan upaya terbaik untuk melindungi dan mengamankan Data
          Pribadi Anda dari akses, pengumpulan, penggunaan atau pengungkapan
          oleh orang-orang yang tidak berwenang dan dari pengolahan yang
          bertentangan dengan hukum, kehilangan yang tidak disengaja, pemusnahan
          dan kerusakan atau risiko serupa. Walau kami akan berusaha sebaik
          mungkin dalam melindungi Data Pribadi Anda, Anda mengakui bahwa kami
          tidak dapat menjamin Data Pribadi Anda tidak akan dicegat, diakses,
          diungkapkan, diubah atau dihancurkan oleh pihak ketiga yang tidak
          berwenang, karena faktor-faktor di luar kendali kami.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2">HAK ANDA TERHADAP DATA PRIBADI ANDA</h1>
        <p className=" my-2">
          Sesuai dengan hukum dan peraturan yang berlaku, Anda berhak untuk
          menarik persetujuan Anda untuk pemrosesan Data Pribadi berupa
          informasi lokasi GPS waktu-nyata (real-time) Anda (dimana kami
          memproses Data Pribadi Anda berdasarkan persetujuan Anda). Jika Anda
          diberi pilihan untuk membagikan Data Pribadi Anda kepada kami, Anda
          selalu dapat memilih untuk tidak melakukannya.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2">PERUBAHAN DAN PEMBARUAN</h1>
        <p className=" my-2">
          Untuk memastikan bahwa Kebijakan Pribadi ini konsisten dengan
          perkembangan kami di masa depan, kami dapat memodifikasi,
          memperbaharui, atau mengubah ketentuan Kebijakan Privasi ini atas
          kebijakan kami sendiri. Perubahan tersebut akan diberitahukan kepada
          Anda melalui aplikasi. Anda bertanggung jawab untuk meninjau Kebijakan
          Privasi secara teratur.
        </p>
      </div>

      <div className="mt-8">
        <h1 className=" font-bold my-2">HUBUNGI KAMI</h1>
        <p className=" my-2">
          Jika Anda memiliki pertanyaan atau keluhan berdasarkan Kebijakan
          Privasi ini, silakan hubungi kami melalui layanan email: {' '}
          <a className=" text-primary" href="mailto:comute.app@gmail.com">comute.app@gmail.com</a>
        </p>
      </div>
    </div>
  );
}
