import React from "react";

export const Roadmap = React.forwardRef((props, ref) => {
  return (
    <div
      id="roadmap"
      ref={ref}
      className="relative pt-32 px-5 md:pt-40 lg:pt-[100px] xl:px-[100px]"
    >
      <div className=" ">
        <div className=" text-center">
          <h1 className=" text-secondary font-semibold text-3xl leading-10">
            Bagaimana Comute Bekerja?
          </h1>
          <p className=" mt-2 text-comuteDark text-base leading-6 ">
            Yuk, telusuri fitur-fitur yang ada di Comute!
          </p>
        </div>
        <div
          className=" mt-[210px] mb-40
           px-5 lg:px-[50px] py-[36px]  flex flex-row justify-between h-[206px] mx-auto bg-neutral items-center"
        >
          <img src="/images/number/01.png" alt="" />
          <div className=" relative ">
            <img src="/images/number/phone-1.png" alt="" />
          </div>
          <div className="">
            <h1 className=" text-black text-lg ">
              Tentukan{" "}
              <span className=" font-semibold text-primary">Stasiun</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Isi stasiun asal & tujuan, lalu tekan ‘Mulai Perjalanan’
            </p>
          </div>
        </div>
        <div
          className=" mt-[245px] mb-40
           px-5 lg:px-[50px] py-[36px]  flex flex-row justify-between h-[206px] mx-auto bg-neutral items-center"
        >
          <img src="/images/number/02.png" alt="" />
          <div className=" ml-4 w-[370px]">
            <h1 className=" text-black text-lg ">
              Lihat{" "}
              <span className=" font-semibold text-primary">
                Detail Perjalanan
              </span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Kamu bisa lihat sisa stasiun, rute, dan stasiun yang akan kamu
              lewati berikutnya!
            </p>
          </div>
          <div className="relative ">
            <img src="/images/number/phone-2.png" alt="" />
          </div>
        </div>
        <div
          className=" mt-[245px] mb-40
           px-5 lg:px-[50px] py-[36px]  flex flex-row justify-between h-[206px] mx-auto bg-neutral items-center"
        >
          <img src="/images/number/03.png" alt="" />
          <div className="relative ">
            <img src="/images/number/phone-3.png" alt="" />
          </div>
          <div className=" w-[370px]">
            <h1>
              Dapatkan{" "}
              <span className=" font-semibold text-primary">Notifikasi</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Comute akan memberi tahu jika kamu harus turun di stasiun transit
              atau tiba di stasiun tujuan.
            </p>
          </div>
        </div>
        <div
          className=" mt-[245px] mb-40
           px-5 lg:px-[50px] py-[36px]  flex flex-row justify-between h-[206px] mx-auto bg-neutral items-center"
        >
          <img src="/images/number/04.png" alt="" />

          <div className=" ml-4 w-[370px]">
            <h1>
              Simpan{" "}
              <span className=" font-semibold text-primary">Rute Favorit</span>
            </h1>
            <p className=" mt-2 text-sm text-black">
              Setelah selesai perjalanan, kamu bisa menyimpannya menjadi rute
              favorit kamu.
            </p>
          </div>
          <div className="relative ">
            <img src="/images/number/phone-4.png" alt="" />
          </div>
        </div>
        <div
          className=" mt-[245px] mb-40
           px-5 lg:px-[50px] py-[36px]  flex flex-row justify-between h-[206px] mx-auto bg-neutral items-center"
        >
          <img src="/images/number/05.png" alt="" />

          <div className="relative ">
            <img src="/images/number/phone-5.png" alt="" />
          </div>

          <div className=" w-[470px]">
            <h1>
              Bantuan{" "}
              <span className=" font-semibold text-primary">Komunikasi</span>
            </h1>
            <p classNames=" mt-2 text-sm text-black">
              Cukup memilih atau mengetik pertanyaan, lalu dapatkan jawaban
              lawan bicara dengan fitur live transcribe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
})
