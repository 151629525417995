import React from "react";

export default function Hero() {
  return (
      <div
        id="banner"
        className=" mt-[50px] bg-primary py-[30px]"
      >
        <div className=" text-center ">
          <h1 className=" font-semibold  text-base lg:text-3xl text-white">Comute tersedia di App Store</h1>
          <p className=" mt-2 text-white text-sm md:text-base leading-6 font-light ">Yuk, gunakan Comute sebagai teman perjalanan kamu!</p>
          <a
          href="https://apps.apple.com/id/app/comute/id6444108882"
            target="_blank"
          className=" mt-7 text-center inline-block w-[85px] h-[25px]  lg:w-[190px] lg:h-[56px]" rel="noreferrer"
          >
          <img src="/images/banner-appstore.png" alt=" w-[85px] h-[25px] lg:w-auto lg:h-auto m-0" />
          </a>
        </div>
      </div>
  );
}
