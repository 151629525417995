import React from "react";

export default function Footer() {

  return (
      <div
        id="footer"
        className=" px-5 lg:px-[100px] pt-5 pb-10 lg:py-[25px] bg-primary"
      >
      <div className=" text-white flex flex-row justify-between ">
        <div className="flex flex-col justify-between">
          <img src="/images/footer-logo.png" alt="" className=" w-[84px] h-[25px] lg:w-auto lg:h-auto" />
          <p className=" mt-2 text-sm lg:text-base  font-normal">Copyright 2022 © Comute</p>
        </div>
        <div className=" flex flex-col xl:flex-row text-sm md:text-base rightWrapper justify-between">
          <div className="">
            <p className=" font-semibold ">Contact us</p>
            <p className=" mt-2  font-normal">comute.app@gmail.com</p>
          </div>
          <div className=" flex flex-row mt-3 lg:mt-0 xl:ml-[55px]">
            <a href="https://www.youtube.com/@comute">
              <img src="/icons/youtube.png" alt="" className=" w-[29px] h-[22px] xl:w-auto xl:h-[37px]" />
            </a>
            <div className=" ml-3 lg:ml-6">
              <a href="https://www.instagram.com/comute.app/">
                <img src="/images/instagram.png" alt="" className="w-[22px] h-[22px] xl:w-[37px] xl:h-[37px]" />
              </a>
            </div>
          </div>
        </div>

      </div>
      </div>
  );
}
