import React from "react";

export const Hero = React.forwardRef((props, ref) => {
  return (
      <div
        id="hero"
        ref={ref}
        className="bg-neutral"
      >
        <div className=" flex flex-col-reverse md:flex-row justify-between px-5 md:px-10 xl:px-[100px] pt-10 pb-20 "
          style={{
            backgroundImage: `url("/images/full-hero-background.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}>
          <div className="left max-w-[510px] ">
            <img
              src="/images/logo.png"
              alt=""
              className=" mb-5 md:mb-12 w-[38px] h-[38px] md:max-w-[60px] md:max-h-[60px]"
            />
            <h1 className="text-comuteDark font-regular text-[26px] md:text-5xl">
              Sahabat Perjalanan <br />
              <span className="text-primary font-semibold">Teman Tuli</span>
            </h1>
            <p className=" text-sm md:text-base mt-4 text-comuteGray opacity-75 max-w-[486px]">
              Siap membantu kamu bepergian dengan KRL mulai dari awal berangkat
              hingga tiba di stasiun tujuan!
            </p>
            <a href="https://apps.apple.com/id/app/comute/id6444108882">

            <button
              type="button"
              className=" bg-primary px-2 py-1 w-60 md:w-80 h-14 rounded-md text-white mt-5 md:mt-[84px] relative hover:bg-secondary"
            >
              Download di App Store
            </button>
          </a>

          </div>
          <div className="right">
          <div className="relative hidden md:block">
              <img src="/images/hero-mockup.png" alt="" />
              <a
              href="https://apps.apple.com/id/app/comute/id6444108882"
                target="_blank"
                className=" absolute bottom-[90px] right-0" rel="noreferrer"
              >
                <img src="/images/appstore.png" alt="" />
              </a>
            </div>
          <div className=" mb-6 block md:hidden">
            <img src="/images/sm-hero-mockup.png" alt="" />
          </div>
          </div>
        </div>
      <div className=" flex flex-col md:flex-row justify-between pt-10 px-5 md:px-10 xl:px-[100px] items-center"
          style={{
            backgroundImage: `url("/images/full-hero-background-2.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        >
          <div className="left max-w-[510px] mx-auto lg:mx-0 ">
          <h1 className="text-secondary text-center lg:text-left font-semibold text-[26px] md:text-5xl">
              Membantu Teman Tuli Bertransportasi Menggunakan KRL
            </h1>
          <p className=" text-sm md:text-base text-center lg:text-left mt-8 text-comuteGray opacity-75 max-w-[486px]">
              Comute merupakan aplikasi komuter untuk teman Tuli yang memiliki fitur arahan rute perjalanan dengan peringatan untuk turun stasiun, sekaligus fitur bantuan berkomunikasi dengan teman dengar yang memberikan kemudahan & kenyamanan dalam bertransportasi menggunakan KRL.
            </p>
          </div>
          <div className=" hidden lg:block right">
            <div className="">
              <img src="/images/image-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
  );
})
