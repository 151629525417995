import Home from './pages/home';
import PrivacyAndPolicy from './pages/privacy-and-policy';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';

function App() {


  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/privacy-and-policy' element={<PrivacyAndPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
