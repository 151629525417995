import React, { useState } from "react";

export default function Testimonial() {

  const [showModal, setShowModal] = useState(false);

  return (
      <div
        id="testimonial"
        className=" pt-10 md:pt-32 lg:pt-40 xl:pt-60 px-5 md:px-10 xl:px-[100px]"
        style={{
          backgroundImage: `url("/images/video-background.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
          <div className="">
            <img
              src="/images/video-thumbnail.png"
              alt=""
              className=" cursor-pointer mx-auto"
              onClick={() => setShowModal(!showModal)}
            />
          </div>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg relative outline-none focus:outline-none">
                  {/*body*/}
                  <div className="relative flex flex-row -mr-8 items-start">
                    <iframe title="comute-branding" allow="autoplay" className=" w-[300px] h-[200px] md:w-[600px] md:h-[350px] lg:w-[877px] lg:h-[493px]"
                      src="https://www.youtube.com/embed/yEUfxk7q8a0?autoplay=1">
                    </iframe>
                    <button
                      className=" ml-2 font-bold uppercase text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      <img src="/icons/close.png" alt="" className=" w-7 h-7" />
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
  );
}
