import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import cn from "classnames";

export default function Navbar({links, activeIndex }) {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const [mounted, setMounted] = useState(false);

  const [onTop, setOnTop] = useState(true);


  useEffect(() => {
    setMounted(true);
    const handleScroll = () => {
      setOnTop(window.pageYOffset === 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={cn(
        onTop
          ? "hidden"
          : " fixed shadow-sm dark:shadow-gray-300 md:border-b  md:border-0 ",
        "w-full flex items-center flex-wrap bg-white px-5 py-2 md:px-10 lg:px-[100px] md:py-5 z-40"
      )}
    >
      <a className="inline-flex items-center " href="/">
        <img
          src="/images/navbar-logo.png"
          alt="Comute App"
          className=" hidden lg:block"
        />
        <img
          src="/images/logo.png"
          alt="Comute App"
          className=" block lg:hidden w-[32px] h-[32px]"
        />
      </a>
      <button
        className=" inline-flex p-3 rounded lg:hidden text-fontWhite ml-auto hover:text-fontWhite outline-none"
        onClick={handleClick}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      {/*Note that in this div we will use a ternary operator to decide whether or not to display the content of the div  */}
      <div
        className={`${
          active ? "" : "hidden"
        }  w-full pt-2 lg:inline-flex lg:flex-grow lg:w-auto`}
      >
        <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
          {links.map((item, index) => (
            <Link
              activeClass="active"
              key={index}
              className={cn(index === activeIndex ? "border-b-2 border-primary" : "","mt-6 lg:mt-0 lg:inline-flex lg:w-auto hover:text-primary w-full ml-0 lg:ml-16 text-fontWhite font-medium items-center justify-center cursor-pointer text-comuteGray")}
              to={item.to}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {item.text}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}
