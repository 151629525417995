import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Team = React.forwardRef((props, ref) => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true,
          arrows: false,
          centerMode: true
        },
      },
    ],
  };

  return (
    <div
      id="team"
      ref={ref}
      className=" bg-neutral py-[30px] px-5 lg:px-[100px] lg:py-[100px]"
    >
      <div className=" text-center ">
        <h1 className=" font-semibold text-3xl text-secondary">Siapa dibalik Comute?</h1>
        <p className=" mt-2 text-black text-base leading-6 font-light ">Yuk, kenalan dengan anggota tim hebat yang membuat Comute</p>
      </div>
      <Slider
        {...settings}
        className=" mt-[90px] mx-auto w-full"
      >
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black rounded-[5px] w-[178px] h-[190px] m-0">
              <img src="/images/team/team-1.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px] m-0" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4  translate-y-1/2 translate-x-1/2">
                <div className=" text-white ">
                  <p>Contact Me</p>
                  <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/nat-audrey/" >
                      <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                    </a>
                  <a href="https://www.instagram.com/nathania_audreyy/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                  </div>
                </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Brigitta N. Audrey
            </p>
            <p className="text-comuteGray font-normal opacity-75">Project Manager
              & Designer</p>
          </div>
        </div>
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black rounded-[5px] w-[178px] h-[190px] m-0">
              <img src="/images/team/team-2.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px] m-0" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4 translate-y-1/2 translate-x-1/2">
              <div className=" text-white ">
                <p>Contact Me</p>
                <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/teuku-fachreza-fathan-57b1771aa/" >
                    <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                  </a>
                  <a href="https://www.instagram.com/rezafath254/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Teuku Fachreza F.
            </p>
            <p className="text-comuteGray font-normal opacity-75">Engineer Lead</p>
          </div>
        </div>
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black  w-[178px] h-[190px]">
              <img src="/images/team/team-3.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px]" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4 translate-y-1/2 translate-x-1/2">
              <div className=" text-white ">
                <p>Contact Me</p>
                <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/hanselmatthew/" >
                    <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                  </a>
                  <a href="https://www.instagram.com/hnslmp/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Hansel Matthew
            </p>
            <p className="text-comuteGray font-normal opacity-75">Engineer</p>
          </div>
        </div>
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black rounded-[5px] w-[178px] h-[190px]">
              <img src="/images/team/team-4.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px]" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4 translate-y-1/2 translate-x-1/2">
              <div className=" text-white ">
                <p>Contact Me</p>
                <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/melvin-liu/" >
                    <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                  </a>
                  <a href="https://www.instagram.com/mlven23/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Melvin Liu
            </p>
            <p className="text-comuteGray font-normal opacity-75">Engineer</p>
          </div>
        </div>
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black rounded-[5px] w-[178px] h-[190px]">
              <img src="/images/team/team-6.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px]" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4 translate-y-1/2 translate-x-1/2">
              <div className=" text-white ">
                <p>Contact Me</p>
                <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/rantisidauruk/" >
                    <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                  </a>
                  <a href="https://www.instagram.com/rantisidauruk/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Ranti Sidauruk
            </p>
            <p className="text-comuteGray font-normal opacity-75">Designer Lead</p>
          </div>
        </div>
        <div className="max-w-[180px]">
          <div className=" group relative">
            <div className=" bg-black rounded-[5px] w-[178px] h-[190px]">
              <img src="/images/team/team-5.png" alt="" className="group-hover:opacity-50 w-[178px] h-[190px]" />
            </div>
            <div className="absolute opacity-0 group-hover:opacity-100 duration-300 text-center top-1/4 translate-y-1/2 translate-x-1/2">
              <div className=" text-white ">
                <p>Contact Me</p>
                <div className=" mt-2 flex flex-row max-w-[50px] justify-between mx-auto">
                  <a href="https://www.linkedin.com/in/laura-sudharma/" >
                    <img src="/icons/linkedin.png" alt="" className=" w-5 h-5" />
                  </a>
                  <a href="https://www.instagram.com/inthemidstofstars/" >
                    <img src="/icons/instagram.png" alt="" className=" w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-5 text-center text-base">
            <p className=" text-comuteGray font-normal">
              Laura Sudharma
            </p>
            <p className="text-comuteGray font-normal opacity-75">Designer & QA Lead</p>
          </div>
        </div>

      </Slider>
    </div>
  );
})
